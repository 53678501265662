import { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DOMPurify from 'dompurify';


const MainsAnswer = ({ mainsAnswer, activeQuestionIndex, mainsQuestion, showManualQuestionUpload, modelAnswer }) => {

  const sanitizeHTML = (html) => {
    return DOMPurify.sanitize(html);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Chip label="Your Review" color="primary" variant="outlined" sx={{ mb: 0 }} />
        <div
          style={{  border: "2px solid #0f6fff", padding: "1rem", boxShadow: "0 0 10px #0f6fff" }}
        >
          <Typography
            variant="body5"
            component="div"
            gutterBottom
            color="text.primary"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(mainsAnswer) }}
          />
        </div>
        {!showManualQuestionUpload && modelAnswer.length > 0 ? (
          <>
            <Chip label="Model Answer" variant="outlined" sx={{ mb: 1, color: "#86198f" }} />
            <Typography
              variant="body5"
              component="div"
              gutterBottom
              sx={{ border: "2px solid #86198f", p: 2, boxShadow: "0 0 10px #86198f" }}
              style={{ whiteSpace: "pre-line" }}
              dangerouslySetInnerHTML={{ __html: modelAnswer }}
            />
          </>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default MainsAnswer;
