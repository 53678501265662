import { useEffect, useState, useContext } from "react";
import React from "react";
import Widget from "../Card/Card";
import "./RightPane.css";
// import axios from "axios";
// import dayjs from "dayjs";
// import PropTypes from "prop-types";
import Button from "@mui/material/Button";
// import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import UserContext from "../../UserContext";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import Checkbox from "@mui/material/Checkbox";
// import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import _ from "lodash";
import Grid from "@mui/material/Grid";
// import configData from "../../config.json";
import BootstrapDialogTitle from "../Modals/BootstrapDialogTitle";
import BootstrapDialog from "../Modals/Dialog";
import WidgetContext from "../../WidgetContext";
// import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import OptionalCard from "../OptionalCard/OptionalCard";
// import usePopulateWidgetState from "../../hooks/widgetHooks/usePopulateWidgetState";
import { editWidgetSource, performUserWidgetAction, populateEditWidgetModalData, populateWidgetState, undoUserWidgetActions } from "../../utils";
import CalendarContext from "../../CalendarContext";
import { Box } from "@mui/material";
// import OptionalCardData from "../OptionalCard/OptionalCardData.json";

//
// let dataToDisplayOptionalCard = {
//     "Anthropology": { "Title": [], "Interesting articles": [] },
//     "Economics": { "Title": [], "Interesting articles": [] },
//     "Geography": { "Title": [], "Interesting articles": [] },
//     "Geology": { "Title": [], "Interesting articles": [] },
//     "History": { "Title": [], "Interesting articles": [] },
//     "Law": { "Title": [], "Interesting articles": [] },
//     "Management": { "Title": [], "Interesting articles": [] },
//     "Philosophy": { "Title": [], "Interesting articles": [] },
//     "Political Science": { "Title": [], "Interesting articles": [] },
//     "Psychology": { "Title": [], "Interesting articles": [] },
//     "Public Administration": { "Title": [], "Interesting articles": [] },
//     "Sociology": { "Title": [], "Interesting articles": [] }
// };
// let index = 0;
// for (let temp of OptionalCardData) {
//     //
//     let idk = Object.keys(temp).filter((subject) => OptionalCardData[index][subject] === "1");

//     for (let i of idk) {
//         dataToDisplayOptionalCard[i]["Title"].push(temp.Title);
//         dataToDisplayOptionalCard[i]["Interesting articles"].push(temp["Interesting articles"]);
//     }

//     index++;
// }
//

// get the SOURCES from to the config to map to the sources
// const SOURCES = configData["sources"];
// Toast after success on edit source for widget
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TransitionEditWidget = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// let listOfSubjects = ["Anthropology", "Economics", "Geography", "Geology", "History", "Law", "Management", "Philosophy", "Political Science", "Psychology", "Public Administration", "Sociology"];

const RightPane = () => {
  const [showCompleted] = useState(false);
  const [openLoginPrompt, setLoginPrompt] = useState(false);
  const [loggedInUserDetails] = useContext(UserContext);
  const [cardData, setCardData] = useContext(WidgetContext);
  const [calendarContext, setCalendarContext] = useContext(CalendarContext);
  const gaEventTracker = useAnalyticsEventTracker("Home page");
  const [openEditWidgetModal, setOpenEditWidgetModal] = useState(false);
  const [editWidgetModalData, setEditWidgetModalData] = useState({ articles: [] });
  const [editWidgetModalDataWhenOpened, setEditWidgetModalDataWhenOpened] = useState({ articles: [] });
  const [editWidgetSuccessToast, setEditWidgetSuccessToast] = useState(false);
  const [editWidgetFailureToast, setEditWidgetFailureToast] = useState(false);
  const [warnBeforeHideAndRemoveModal, setWarnBeforeHideAndRemoveModal] = useState(false);
  const [firstTimeMarkHideAndRemove, setFirstTimeMarkHideAndRemove] = useState(true);
  const [firstTimeMarkHideAndRemoveModalState, setFirstTimeMarkHideAndRemoveModalState] = useState({});
  const [showHomeLoadingProgressBar, setShowHomeLoadingProgressBar] = useState(false);
  const [showEditWidgetLoadingProgressBar, setShowEditWidgetLoadingProgressBar] = useState(false);

  let navigate = useNavigate();

  const handleLoginPromptOpen = () => {
    setLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setLoginPrompt(false);
  };

  const handleOpenWarnBeforeHideAndRemoveModalOpen = () => {
    setWarnBeforeHideAndRemoveModal(true);
  };

  const handleWarnBeforeHideAndRemoveModalClose = () => {
    setWarnBeforeHideAndRemoveModal(false);
  };

  const handleLoginButtonPrompt = () => {
    navigate("/login");
  };

  // const handleEditWidgetModalOpen = () => {
  //   setOpenEditWidgetModal(true);
  // };

  const handleEditWidgetModalClose = () => {
    setOpenEditWidgetModal(false);
  };

  // const handleEditWidgetSuccessToastOpen = () => {
  //   setEditWidgetSuccessToast(true);
  // };

  const handleEditWidgetSuccessToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEditWidgetSuccessToast(false);
  };

  // const handleEditWidgetFailureToastOpen = () => {
  //   setEditWidgetFailureToast(true);
  // };

  const handleEditWidgetFailureToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setEditWidgetFailureToast(false);
  };

  useEffect(() => {
    try {
      getPopulateWidgetState();
    } catch (error) {}
  }, [loggedInUserDetails, editWidgetSuccessToast, editWidgetFailureToast]);

  const getPopulateWidgetState = async () => {
    try {
      setShowHomeLoadingProgressBar(true);
      let widgetData = await populateWidgetState(loggedInUserDetails, calendarContext.contentRequestDate);
      //
      setShowHomeLoadingProgressBar(false);
      setCalendarContext({ CalendarProgressValue: widgetData[1] });
      setCardData(widgetData[0]);
    } catch (error) {
      setShowHomeLoadingProgressBar(false);
    }
  };
  const handleLinkMarkDone = async (event) => {
    if (loggedInUserDetails.name) {
      let content = JSON.parse(event.currentTarget.dataset.content);
      //
      let cardTitle = event.currentTarget.dataset.cardtitle;
      let toggleThisRef = content.ref;
      let cardDataTemp = _.cloneDeep(cardData);
      let markedDoneBefore = false;
      let index = 0;
      for (let article of cardDataTemp[cardTitle].articles) {
        if (article.ref === toggleThisRef) {
          markedDoneBefore = cardDataTemp[cardTitle]["articles"][index].markDone;
          cardDataTemp[cardTitle]["articles"][index].markDone = !cardDataTemp[cardTitle]["articles"][index].markDone;
          break;
        }
        index++;
      }
      //
      //TODO: set state should be inside api call
      setCardData(_.cloneDeep(cardDataTemp));
      // was marked done false before
      if (!markedDoneBefore) {
        await performUserWidgetAction("mark_as_read", cardData[cardTitle]["articles"][index]["ref"], cardTitle, loggedInUserDetails);
        return gaEventTracker(`widget_${cardTitle}_link_mark_done`);
      } else {
        // mark undone api call
        await undoUserWidgetActions(cardData[cardTitle]["articles"][index]["ref"], "mark_as_read", loggedInUserDetails);
        return gaEventTracker(`widget_${cardTitle}_undo_mark_as_read`);
      }
    } else {
      handleLoginPromptOpen();
    }
  };

  const handleReadLater = async (event) => {
    if (loggedInUserDetails.name) {
      let content = JSON.parse(event.currentTarget.dataset.content);
      let cardTitle = event.currentTarget.dataset.cardtitle;
      let toggleThisRef = content.ref;
      let cardDataTemp = _.cloneDeep(cardData);
      let savedLaterBefore = false;

      let index = 0;
      for (let article of cardDataTemp[cardTitle].articles) {
        if (article.ref === toggleThisRef) {
          savedLaterBefore = cardDataTemp[cardTitle]["articles"][index].savedLater;
          cardDataTemp[cardTitle]["articles"][index].savedLater = !cardDataTemp[cardTitle]["articles"][index].savedLater;
          break;
        }
        index++;
      }

      // TODO: set state should be inside api call
      setCardData(_.cloneDeep(cardDataTemp));
      if (!savedLaterBefore) {
        await performUserWidgetAction("read_later", cardData[cardTitle]["articles"][index]["ref"], cardTitle, loggedInUserDetails);

        return gaEventTracker(`widget_${cardTitle}_read_later`);
      } else {
        await undoUserWidgetActions(cardData[cardTitle]["articles"][index]["ref"], "read_later", loggedInUserDetails);

        return gaEventTracker(`widget_${cardTitle}_undo_read_later`);
      }
    } else {
      handleLoginPromptOpen();
    }
  };

  const handleLinkRemove = async (event) => {
    let index;
    let cardTitle;
    let oldArticles;

    try {
      index = event.currentTarget.dataset.index;
      cardTitle = event.currentTarget.dataset.cardtitle;

      if (loggedInUserDetails.name) {
        if (firstTimeMarkHideAndRemove) {
          setFirstTimeMarkHideAndRemove(false);
          handleOpenWarnBeforeHideAndRemoveModalOpen();
          setFirstTimeMarkHideAndRemoveModalState({ index: index, cardTitle: cardTitle });
        } else {
          await performUserWidgetAction(
            "remove_from_feed",
            cardData[cardTitle]["articles"][index]["ref"],
            cardTitle,
            loggedInUserDetails
          );

          // remove from ui
          oldArticles = cardData[cardTitle].articles.slice();
          oldArticles.splice(index, 1);

          let data = { ...cardData };
          data[cardTitle].articles = oldArticles.slice();
          setCardData(data);
        }
      } else {
        handleLoginPromptOpen();
      }
    } catch (error) {}

    return gaEventTracker(`widget_${cardTitle}_link_remove`);
  };

  const handleLinkRemoveModal = async (event) => {
    let { index, cardTitle } = firstTimeMarkHideAndRemoveModalState;
    let oldArticles;

    try {
      await performUserWidgetAction("remove_from_feed", cardData[cardTitle]["articles"][index]["ref"], cardTitle, loggedInUserDetails);

      // remove from ui
      oldArticles = cardData[cardTitle].articles.slice();
      oldArticles.splice(index, 1);

      let data = { ...cardData };
      data[cardTitle].articles = oldArticles.slice();
      setCardData(data);
      handleWarnBeforeHideAndRemoveModalClose();
    } catch (error) {
      handleWarnBeforeHideAndRemoveModalClose();
    }

    return gaEventTracker(`widget_${cardTitle}_link_remove`);
  };

  const handleEditWidgetSourceToggle = (event) => {
    if (loggedInUserDetails.name) {
      // do edit stuff
      setOpenEditWidgetModal(true);
      getPopulateEditWidgetModalData(event.currentTarget.dataset.cardtitle.split(" ").join("_"));
    } else {
      handleLoginPromptOpen();
    }
  };

  const getPopulateEditWidgetModalData = async (widgetName) => {
    try {
      setShowEditWidgetLoadingProgressBar(true);
      let data = await populateEditWidgetModalData(widgetName, loggedInUserDetails);
      setShowEditWidgetLoadingProgressBar(false);
      setEditWidgetModalData(_.cloneDeep(data));
      setEditWidgetModalDataWhenOpened(_.cloneDeep(data));
    } catch (error) {
      setShowEditWidgetLoadingProgressBar(false);
    }
  };

  const handleEditWidgetRulesChange = async () => {
    let rulesToRemove = [];
    let rulesToAdd = [];
    let index = 0;

    // compare the state when the modal just opened vs how the options were at the end
    for (let i = 0; i < editWidgetModalData.articles.length; i++) {
      if (editWidgetModalData.articles[index].checked !== editWidgetModalDataWhenOpened.articles[index].checked) {
        if (editWidgetModalData.articles[index].checked) {
          rulesToAdd.push(editWidgetModalData.articles[index].rule);
        } else {
          rulesToRemove.push(editWidgetModalData.articles[index].rule);
        }
      }
      index++;
    }

    try {
      let responseRemove;
      let responseAdd;

      if (rulesToRemove.length > 0) {
        responseRemove = await editWidgetSource(editWidgetModalData, "remove", rulesToRemove, loggedInUserDetails);
      }
      if (rulesToAdd.length > 0) {
        responseAdd = await editWidgetSource(editWidgetModalData, "add", rulesToAdd, loggedInUserDetails);
      }

      setOpenEditWidgetModal(false);
      if (responseAdd?.status === 200 || responseRemove?.status === 200) {
        setEditWidgetSuccessToast(true);
      }
    } catch (error) {
      setOpenEditWidgetModal(false);
      setEditWidgetFailureToast(true);
    }
  };

  return (
    <div className="right-pane text-start">
      {
        // Hacky css used here for circular progress
        // TODO: review the css to make it better
      }
      {showHomeLoadingProgressBar ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} style={{ minHeight: "60vh" }}>
          <CircularProgress color="info" />
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, justifyContent: "center", alignContent: "center", mt: 4, ml: 1, mr: 2, mb: 4 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
            {Object.keys(cardData).length > 0
              ? Object.keys(cardData).map((cardTitle, index) => {
                  let articles = cardData[cardTitle]["articles"];
                  let data = {
                    cardTitle: cardTitle,
                    articles: articles,
                  };
                  return (
                    <Widget
                      data={data}
                      key={index}
                      handleLinkMarkDone={handleLinkMarkDone}
                      handleLinkRemove={handleLinkRemove}
                      handleReadLater={handleReadLater}
                      showCompleted={showCompleted}
                      handleEditWidgetSourceToggle={handleEditWidgetSourceToggle}
                    />
                  );
                })
              : null}
            {/* <OptionalCard listOfSubjects={listOfSubjects} /> */}
          </Grid>
        </Box>
      )}
      <BootstrapDialog onClose={handleLoginPromptClose} aria-labelledby="login prompt" open={openLoginPrompt}>
        <BootstrapDialogTitle id="login with your google account" onClose={handleLoginPromptClose}>
          Login for this feature
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Login to get access to this feature and many more...</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLoginButtonPrompt}>
            Login
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <BootstrapDialog
        onClose={handleWarnBeforeHideAndRemoveModalClose}
        aria-labelledby="Mark read and hide prompt"
        open={warnBeforeHideAndRemoveModal}>
        <BootstrapDialogTitle id="markReadAndHidePrompt" onClose={handleWarnBeforeHideAndRemoveModalClose}>
          Are you sure?
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Hiding for now, to permantently remove source use settings icon</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleLinkRemoveModal}>
            Mark as hide and remove
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Dialog fullScreen open={openEditWidgetModal} onClose={handleEditWidgetModalClose} TransitionComponent={TransitionEditWidget}>
        <AppBar sx={{ position: "relative" }} style={{ background: "#000" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleEditWidgetModalClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {`${_.startCase(_.toLower(editWidgetModalData.cardTitle))} - Select your sources`}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleEditWidgetRulesChange}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {showEditWidgetLoadingProgressBar ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} style={{ minHeight: "60vh" }}>
              <CircularProgress color="info" />
            </Box>
          ) : (
            editWidgetModalData?.articles.map((article) => {
              return (
                <>
                  <ListItem
                    alignItems="flex-start"
                    key={article.link}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        // icon={<RemoveCircleIcon />}
                        // checkedIcon={<RemoveCircleOutlineIcon />}
                        onChange={() => {
                          let temp = Object.assign({}, editWidgetModalData);
                          let ref = article.ref;
                          let index = 0;
                          for (let article of editWidgetModalData.articles) {
                            if (article.ref === ref) {
                              temp.articles[index].removed = !temp.articles[index].removed;
                              temp.articles[index].checked = !temp.articles[index].checked;
                            }
                            index++;
                          }

                          setEditWidgetModalData({ ...temp });
                        }}
                        // onChange={handleEditWidgetSourceCheckboxToggle(article.link)}
                        checked={!article.removed}
                      />
                    }>
                    <ListItemAvatar>
                      <Avatar alt={article.link} src={article.imgSrc} variant="square" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <a href={`${article.link}`} target="_blank" rel="noreferrer" style={{ all: "unset", cursor: "pointer" }}>
                          {article.title}
                        </a>
                      }
                      secondary={
                        <>
                          <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            // color="text.primary"
                            color={`${article.removed ? "gray" : "black"}`}>
                            {article.source}
                          </Typography>
                        </>
                      }
                      style={{ color: `${article.removed ? "gray" : "black"}` }}
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              );
            })
          )}
        </List>
      </Dialog>
      {editWidgetSuccessToast ? (
        <Snackbar open={editWidgetSuccessToast} autoHideDuration={6000} onClose={handleEditWidgetSuccessToastClose}>
          <Alert onClose={handleEditWidgetSuccessToastClose} severity="success" sx={{ width: "100%" }}>
            Your sources were edited successfully
          </Alert>
        </Snackbar>
      ) : null}
      {editWidgetFailureToast ? (
        <Snackbar open={editWidgetFailureToast} autoHideDuration={6000} onClose={handleEditWidgetFailureToastClose}>
          <Alert onClose={handleEditWidgetFailureToastClose} severity="error" sx={{ width: "100%" }}>
            Failed to update your sources, please try again
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default RightPane;
