import * as React from "react";
import {
  getPaymentAmount,
  getReferralCode,
  isLoggedIn,
  pay,
  paymentStatus,
} from "../../utils";
import {
  Link,
  Button,
  Typography,
  Box,
  Modal,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { isBrowser, isMobile, isIOS } from "react-device-detect";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationExit from "./ConfirmationExit";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import UserContext from "../../UserContext";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from 'axios';

const PaymentScreen = ({
  paymentModalOpen,
  handleCloseNestedPaymentModal,
  handleOpenNestedPaymentModal,
  payData,
  loaderPayModal,
  handleCloseNestedPaymentModalOnly,
  paymentModalNestedOpen,
  selectedPlan,
  amount,
  year,
  PAYMENT_PENDING,
}) => {
  const [loggedInUserDetails, setLoggedInUserDetails] =
    React.useContext(UserContext);
  // const [amount, setAmount] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showReferralPrompt, setShowReferralPrompt] = React.useState(false);
  const [appliedReferralCode, setAppliedReferralCode] = React.useState("");

  const capitalizedPlan = selectedPlan;
  let navigate = useNavigate();
  useEffect(() => {
    if (loggedInUserDetails?.name) {
      wrapperGetReferralCode();
      // if (!loggedInUserDetails?.premium) {
      //   wrapperGetPaymentAmount();
      // }
    }
  }, [loggedInUserDetails, PAYMENT_PENDING]);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  const wrapperGetReferralCode = async () => {
    let response = await getReferralCode(loggedInUserDetails);
    setAppliedReferralCode(
      response.data.applied_referral_code
        ? response.data.applied_referral_code
        : ""
    );
  };

  const wrapperGetPaymentAmount = async () => {
    // let response = await getPaymentAmount(loggedInUserDetails);
    // setAmount(response.data.amount / 100);
  };

  const checkPaymentStatus = async (orderId) => {
    try {
      const response = await axios.post(`https://collectorbabu.com/api/payment/v2/status?order_id=${orderId}`);
      if (response.data.status === "success") {
        navigate("/payment-success");
      } else {
        navigate("/payment-failure");
      }
      return response.data;
    } catch (error) {
      console.error("Error checking payment status:", error);
      navigate("/payment-failure");
      return null;
    }
  };

  const startPayment = (orderId, amount) => {
    const options = {
      key: "rzp_live_gFk89O5gymnT9Z",
      // key:"rzp_test_IX1CaFSXg5VP39",
      amount: amount,
      currency: "INR",
      name: "Collectorbabu",
      image: "https://collectorbabu.com/images/mainLogo.png",
      description: "Plan purchase",
      order_id: orderId,
      handler: async function (response) {
        await checkPaymentStatus(orderId);
      },
      prefill: {
        name: loggedInUserDetails.name,
        email: loggedInUserDetails.email,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handlePaymentClick = async () => {
    try {
      // Get order details from backend
      // const response = await pay(loggedInUserDetails, selectedPlan, year);
      // console.log(response);
      // console.log(response)
      // const finalAmount = response.amount * 10;
      startPayment(payData.id, payData.amount);
    } catch (error) {
      console.error("Payment initialization error:", error);
    }
  };
  
  return (
    <Dialog
      style={{}}
      maxWidth="xs"
      open={paymentModalOpen}
      keepMounted
      scroll={"paper"}
    >
      {!loading ? (
        <>
          <DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleOpenNestedPaymentModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* {!isIOS ? (
          <IconButton
            aria-label="back"
            onClick={handleOpenNestedPaymentModal}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <ArrowBackIosNewIcon />
          </IconButton>
        ) : null} */}
          </DialogTitle>
          <DialogContent>
            <Box>
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={5}
              >
                <Alert severity="warning">
                  Please don't close or refresh while payment is processing. It
                  will close automatically.
                </Alert>
                <Typography
                  id="pay-modal-title"
                  variant="h5"
                  component="h2"
                  sx={{ textAlign: "center" }}
                >
                  <p>
                    {/* Pay ₹{amount} <s>₹4,999</s> for UNLIMITED answer evaluation
                    till Mains '24. <br />
                    <br /> 
                    Custom annual plans with full answer sheet(PDF)
                    evaluation of GS/Essay/Optional also available. Email us. */}
                    Gain Exclusive Access to {capitalizedPlan} Plan until Mains '{year?year.slice(-2): year} for just ₹{(payData.amount / 100).toFixed(0)}
                  </p>
                </Typography>
                {/* <Alert severity="info">
                  {appliedReferralCode?.length > 0
                    ? `Referrer code applied: ${appliedReferralCode}`
                    : "Apply Referral below to get ₹100 off"}
                </Alert>
                <Typography
                  id="pay-modal-refferer"
                  variant="h5"
                  component="h2"
                  sx={{ textAlign: "center", mt: 0 }}
                >
                  {appliedReferralCode?.length > 0 ? null : (
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        navigate("/profile");
                        handleCloseNestedPaymentModal();
                      }}
                    >
                      Apply Referral
                    </Button>
                  )}
                </Typography> */}
                {!PAYMENT_PENDING ? (
                      isMobile ? (
                        <Button variant="contained" style={{ marginTop: "20px" }} onClick={handlePaymentClick}>
                          <a
                            // href={`${payData.redirectUrl}`}
                            alt="mobile UPI payment link"
                            style={{ color: "white" }}
                            // rel="noopener noreferrer"
                
                          >
                            Proceed to Pay ₹{(payData.amount / 100).toFixed(0)}
                          </a>
                        </Button>
                      ) : (
                        <>
                          <Typography
                            id="pay-modal-description"
                            sx={{ mt: 2, ml: 1, display: "flex", justifyContent: "center", fontSize: 12, color: "black" }}
                          >
                            {/* {isMobile ? "Or " : null} Scan the code to pay */}
                            You will be redirected to 3rd Party site to complete the payment
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mb: 3 }}
                          >
                            <Button variant="contained" style={{ marginTop: "20px" }} onClick={handlePaymentClick}>
                              <a
                                // href={`${payData.redirectUrl}`}
                                alt=""
                                style={{ color: "white" }}
                                // rel="noopener noreferrer"
                              >
                                Proceed to pay ₹{(payData.amount / 100).toFixed(0)}
                              </a>
                            </Button>
                            </Stack>
                        </>
                      )
                    ) : (
                      <>
                      <Typography
                            id="pay-modal-description"
                            sx={{ mt: 2, ml: 1, display: "flex", justifyContent: "center", fontSize: 12, color: "black" }}
                          >
                            {/* {isMobile ? "Or " : null} Scan the code to pay */}
                            Waiting for your payment Confirmation...
                          </Typography>
                      <CircularProgress color="info" />
                      </>
                    )}
              </Stack>

              
            </Box>
          </DialogContent>
        </>
      ) : (
        <CircularProgress color="info" />
      )}

      <ConfirmationExit
        closeAllModals={handleCloseNestedPaymentModal}
        handleCloseNestedPaymentModalOnly={handleCloseNestedPaymentModalOnly}
        paymentModalNestedOpen={paymentModalNestedOpen}
      />
    </Dialog>
  );
};

export default PaymentScreen;
