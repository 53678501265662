import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery,createTheme, Box } from '@mui/material';
import { isLoggedIn} from "../../utils";
import AINavbar from '../AINavbar';
import UserContext from "../../UserContext";
import Footer from "../Footer/Footer";
import LeftPane from "../LeftPane/LeftPane";
import OnScreenMenu from '../OnScreenMenu'; 
import './index.css';

const PaymentFailureDashboard = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const navigate = useNavigate();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <LeftPane Component={PaymentFailure} />
          <Footer />
        </>
      ) : (
        <>
          <AINavbar />
          <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <div style={{ marginTop: '0px' }}>
              <PaymentFailure />
            </div>
          </Box>
          <OnScreenMenu />
          <Footer />
        </>
      )}
    </>
  );
};

const PaymentFailure = () => {
  const [countdown, setCountdown] = useState(7);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    const timer = setTimeout(() => {
      navigate('/pricing');
    }, 7000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [navigate]);

  const handleRedirect = () => {
    navigate('/pricing');
  };

  return (
  <>
          

    <div className="payment-failure-container">
      <video className="failure-video" autoPlay loop muted>
        <source src="/images/paymentFailure.webm" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="message">
        Payment Failed! You will be redirected to the homepage in <span className="redirect-timer">{countdown}</span> seconds.
      </div>
      <button className="home-btn" onClick={handleRedirect}>
        Go to Homepage
      </button>
    </div>
  </>

  );
};

export default PaymentFailureDashboard;
