import { useEffect, useState } from "react";
import "./Card.css";
import EditIcon from '@mui/icons-material/Edit';
// import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// import { experimentalStyled as styled } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, CardMedia, Typography } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Card from "@mui/material/Card";

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: "1rem"
    },
});

const Widget = ({ data,
    handleLinkMarkDone,
    handleLinkRemove,
    showCompleted,
    display_action,
    handleReadLater,
    handleEditWidgetSourceToggle }) => {
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} zeroMinWidth>
            <Paper elevation={3}>
                {/* <div className="mt-4 d-flex"> */}
                <Box sx={{ display: "flex", pt: 2, py: 3, flexWrap: 'wrap', justifyContent: "center" }}>
                    {/* <h5 className="card-title flex-grow-1" style={{ display: "inline", color: "black", textTransform: "capitalize", fontSize: "1.8rem", fontWeight: "bold", textAlign: "center" }}>{data.cardTitle}</h5> */}
                    <Typography variant="h4" style={{ overflowWrap: 'break-word', textTransform: "capitalize", flex: "1 0 75%", textAlign: "center" }}>{data.cardTitle}</Typography>
                    <CustomTooltip title={`Edit ${data.cardTitle}`} placement="top">
                        <Button disableRipple style={{ display: "inline", marginRight: "10px", color: "black", padding: "0px" }} data-cardtitle={data.cardTitle} onClick={handleEditWidgetSourceToggle} id={`edit-${data.cardTitle}2`} >
                            <SettingsIcon sx={{ fontSize: 43, p: 0 }} />
                        </Button>
                    </CustomTooltip>
                </Box>
                {/* <div className="card-body" style={{ "overflowY": "scroll", "height": "60vh" }}> */}
                <Box
                    sx={{
                        height: 500,
                        overflow: "hidden",
                        overflowY: "scroll",
                        p: 1
                    }}
                >

                    {
                        data.articles.length > 0 ?
                            data.articles.map((links, index) => {
                                return (
                                    <Card sx={{ display: "flex", aligItems: "center" }} style={{ boxShadow: "none" }} key={index}>
                                        <CardMedia
                                            component="img"
                                            style={{ "height": "45px", "width": "45px", "marginRight": "10px", flex: '0 1 auto', }}
                                            image={links.imgSrc}
                                            alt={links.title}
                                        />
                                        <Box sx={{ display: "flex", flexDirection: "column" }} style={{ flex: '1 1 auto' }}>
                                            <CardContent sx={{ p: 0 }}>
                                                <Typography component="div" variant="body1">
                                                    <Link href={links.link} underline="none" style={{ color: "black" }} target="_blank" rel="noopener">{`${links.title.length > 50 ? links.title.substring(0, 50) + " ..." : links.title}`}</Link>
                                                </Typography>
                                                <Typography variant="overline" color="text.secondary" component="div">
                                                    {links.source} - {links.pub_date}
                                                </Typography>
                                            </CardContent>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "end", alignItems: "start", pl: 1, pb: 1 }}
                                            style={{ flex: '0 1 auto' }}
                                        >
                                            <CustomTooltip title="Read later" placement="top">
                                                <IconButton
                                                    aria-label="save later"
                                                    data-title={links.title}
                                                    data-content={JSON.stringify(links)}
                                                    data-index={index}
                                                    data-cardtitle={data.cardTitle}
                                                    onClick={handleReadLater}
                                                    style={{ color: `${links.savedLater ? "green" : "#969696"}`, padding: "0px" }}
                                                >
                                                    <BookmarkAddIcon sx={{ fontSize: 30 }} />
                                                </IconButton>
                                            </CustomTooltip>
                                            <CustomTooltip title={`${links.markDone ? "Mark as Unread" : "Mark as Read"}`} placement="top">
                                                <IconButton
                                                    aria-label={`${links.markDone ? "Mark as Unread" : "Mark as Read"}`}
                                                    data-title={links.title}
                                                    data-content={JSON.stringify(links)}
                                                    data-index={index}
                                                    data-cardtitle={data.cardTitle}
                                                    onClick={handleLinkMarkDone}
                                                    style={{ color: `${links.markDone ? "green" : "#969696"}`, padding: "0px" }}
                                                >
                                                    <CheckCircleIcon sx={{ fontSize: 30 }} />
                                                </IconButton>
                                            </CustomTooltip>
                                            <CustomTooltip title="Mark as hide and remove" placement="top">
                                                <IconButton
                                                    aria-label="Mark as hide and remove"
                                                    data-title={links.title}
                                                    data-content={JSON.stringify(links)}
                                                    data-index={index}
                                                    data-cardtitle={data.cardTitle}
                                                    onClick={handleLinkRemove}
                                                    style={{ "color": "#969696", padding: "0px" }}
                                                >
                                                    <CancelIcon sx={{ fontSize: 30 }} />
                                                </IconButton>
                                            </CustomTooltip>
                                        </Box>
                                    </Card>
                                );
                            })
                            :
                            <p>No more {data.cardTitle} links to show</p>
                    }
                </Box>
            </Paper>
        </Grid >
    );
}

export default Widget;