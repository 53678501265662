import React, { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import FastForwardIcon from '@mui/icons-material/FastForward';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import UserContext from "../../UserContext";
import Cookies from 'js-cookie'
import './index.css'
import { Link, useNavigate } from "react-router-dom";
import { WorkspacePremium } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Button } from '@mui/material';
import AssistantIcon from '@mui/icons-material/Assistant';
import BannerContext from "../../bannerContext";
import HeaderBanner from "../HeaderBanner/HeaderBanner";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


function AINavbar({ isScrolled }) {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const navigate = useNavigate();
  const [bannerData, setBannerData] = useContext(BannerContext);

  // console.log("google image", loggedInUserDetails)
  const logout = () => {
    Cookies.remove("google_info");
    Cookies.remove("user_name");
    Cookies.remove("user_id");
    Cookies.remove("email");
    setLoggedInUserDetails(Object.assign({}));
  };
  const handleLoginButton = () => {
    navigate("/login", { state: { from: window.location.pathname } });
  };
  useEffect(() => {
    
  }, [loggedInUserDetails])

  const capitalizeFirstLetter = (str) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  const handleRemoveBanner = () => {
    setBannerData({
      showBanner: "false",
    });

    localStorage.setItem("showBanner", false);
  };
  
  return (
    <>
       {(!loggedInUserDetails.premium && bannerData.showBanner === "true" ) &&
            <HeaderBanner handleRemoveBanner={handleRemoveBanner} />
          }
    <nav className='navbar'>
      <div className='navbar-brand'>
        <Link to='/'>
          <img src="/images/mainLogo.png" alt="collectorbabu-logo" className='collectorbabu-logo' />
        </Link>
      </div>
      <div className='navbar-list'>
        <ul>
        <li><Link to="/">AI Answer Review</Link></li>
          <li><Link to="/360-reader">360 Reader</Link></li>
          <li style={{ position: 'relative' }}>
            <Link to="/flash-card">RattafAI</Link>
            <span style={{ position: 'absolute', top: '-15px', right: '-20px', color: 'red' }}>New</span>
          </li>
          {/* <li style={{ position: 'relative' }}>
            <Link to="/news">The Desi Explainers</Link>
            <span style={{ position: 'absolute', top: '-15px', right: '-20px', color: 'red' }}>New</span>
          </li> */}
          {/* <li><a href="/pyq-fast-track"><FastForwardIcon /> PYQ Fast track</a></li> */}
          {/* <li className="dropdown">
            <a href="/" className="dropbtn">
              Review <FontAwesomeIcon icon={faAngleDown} />
            </a>
            <div className="dropdown-content">
              <a href="/ai-answer-review"><EmojiObjectsIcon /> Ai Answer Review</a>
              <a href="/upsc-mains-2023-essay-gs-questions-model-answers"><WorkOutlineIcon /> Mains 2023</a>
              <a href="/upsc-notes-zero"><BookmarkBorderOutlinedIcon /> Notes Zero</a>
              <a href="/pyqexplorer"><FastForwardIcon /> PYQ Explorer</a>
            </div>
          </li> */}
          <li className="dropdown">
          <Link className="dropbtn">
              Exam Prep <FontAwesomeIcon icon={faAngleDown} />
            </Link>
            <div className="dropdown-content">
            <Link to="/upsc-mains-2024-essay-gs-questions-model-answers"><WorkOutlineIcon /> Mains 2024</Link>
              <Link to="/upsc-mains-2023-essay-gs-questions-model-answers"><WorkOutlineIcon /> Mains 2023</Link>
              <Link to="/upsc-notes-zero"><BookmarkBorderOutlinedIcon /> Notes Zero</Link>
              <Link to="/pyq-fast-track"><FastForwardIcon /> PYQ Fast track</Link>
              <Link to="/upsc-ias-mock-interview"><PersonIcon />Mock Interview</Link>
              <Link to="/pyqexplorer"><FastForwardIcon /> PYQ Explorer</Link>
              <Link to="/news"><AssistantIcon/> The Desi Explainers</Link>
              <Link to="/upsc-dashboard"><AccountTreeIcon /> UPSC Dashboard</Link>
              <Link to="/savedforlater"><BookmarkBorderOutlinedIcon /> Saved For Later</Link>

            </div>
          </li>
         

          {/* <li><a href="/pricing">Buy</a></li> */}
          {/* <li><a href="/mail/u/0/?fs=1&tf=cm&to=support@collectorbabu.com">Contact Us</a></li> */}
          {/* <li><a href="/savedforlater"><BookmarkBorderOutlinedIcon /></a></li> */}
          {/* <li><a href="/profile"><img className="profile-image" src={loggedInUserDetails.picture} alt="profile" /></a></li> */}
         
          {loggedInUserDetails.name ? (
            <li className='dropdown'>
              <Link><img className="profile-image dropbtn" src={loggedInUserDetails.picture} alt="profile" /></Link>
              <div className="dropdown-content">
                <Link to="/profile">Profile</Link>
                {/* <Link to="/dashboard">Dashboard</Link> */}
                <Link to="/" onClick={logout}>Logout </Link>
              </div>
            </li>
          ):("")}
          
          {!loggedInUserDetails.name ? (
            <li>
              <button onClick={handleLoginButton}>
                <ExitToAppIcon /> Login
              </button>
            </li>
          ) : (
            !loggedInUserDetails.premium || loggedInUserDetails.plan==="trial" ? (
              <li>
                <button onClick={() => navigate("/pricing")}>Buy</button>
              </li>
            ) : (
              // <li className="text-base" style={{ position: 'relative' }}>
              //     <WorkspacePremium /> Premium
              //     <span style={{ position: 'absolute', right: '-68px'}}>{(loggedInUserDetails.plan===null) ? "(Zenith)" : <span>  ({capitalizeFirstLetter(loggedInUserDetails.plan)}) </span>}</span>
              // </li>
              <li className="text-base" style={{display:'flex',alignItems:'center',justifyItems:'center'}}>
                  <WorkspacePremium fontSize='large'/>
                  {/* <div className='flex flex-col'> */}
                  <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyItems:'center'}}>
                    Premium
                    <span>
  {loggedInUserDetails.plan === null 
    ? "(Zenith)" 
    : (
      <span>
        ({
          loggedInUserDetails.plan === "zenith_pro" 
          ? "Zenith Pro" 
          : loggedInUserDetails.plan === "zenith_combo" 
          ? "Zenith Combo" 
          : capitalizeFirstLetter(loggedInUserDetails.plan)
        })
      </span>
    )
  }
</span>

                  </div>
              </li>
              // <li className="relative bounce-animation" title='New Update!!'>
              //   {/* <Button variant="contained" sx={{ width: '6rem', backgroundColor: 'wheat', '&:hover': { backgroundColor: '' } }}> */}
              //   <Link to="/pricing"><WorkspacePremium /> Premium</Link>    
              //     <span className="notification-badge absolute top-[-5px] right-[-20px] bg-red-400 rounded-full px-2 text-sm">1</span>
              //   {/* </Button> */}
              // </li>
            )
          )}
          <button onClick={() => navigate("/dashboard")} className='navbar-dashboard-btn'>Dashboard
          </button>
        </ul>
      </div>
    </nav>
    </>
  );
}

export default AINavbar;
