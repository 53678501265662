import { useEffect, useState, useContext } from "react";
import LeftPane from "../LeftPane/LeftPane";
import RightPane from "../RightPane/RightPane";
import "./Home.css";
import ReactGA from "react-ga4";
import withRouter from "../../withRouter";
import Cookies from "js-cookie";
import axios from "axios";
import UserContext from "../../UserContext";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import HeaderBanner from "../HeaderBanner/HeaderBanner";
import StickyFooter from "../StickyFooter/StickyFooter";
import { isLoggedIn } from "../../utils";
import AIFooter from "../AIFooter";
import AINavbar from "../AINavbar";
import { useMediaQuery, createTheme } from '@mui/material';
import Footer from "../Footer/Footer";
import OnScreenMenu from '../OnScreenMenu';

const Home = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 19) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };
  return (
    <>
     {isMobile ? (
       <> 
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>

        <LeftPane Component={RightPane} /> 
      </Box>
    <OnScreenMenu/>
      <Footer />
       </>
     ) : (
      <> 
        <AINavbar  />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Helmet>
          <title>Home - Collector Babu</title>
          <meta
            name="keywords"
            content="Daily quiz, daily current affairs - from Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS and more"
          />
          <meta
            name="description"
            content="Personalised Daily dashboard for UPSC IAS. AI-based learning. Online learning. Track progress in exam preparation. UPSC study materials. | Insights IAS, Vision IAS, IAS Baba, Drishti IAS, ForumIAS | The Hindu | The Indian Express | SansadTV"
          />

          <meta property="og:title" content="Daily Quiz, Daily Current Affairs" />
          <meta property="og:url" content="https://collectorbabu.com/" />
          <meta
            property="og:description"
            content="Practice and Track Daily Current Affairs, Previous Year Questions for UPSC IAS - Pre and Mains, State PCS and many other government exams"
          />
          <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <div style={{ marginTop: '78px' }}> {/* Adjusted the margin-top based on the height of your AINavbar, they were overlapping */}
          <RightPane />
        </div>
      </Box>
      <OnScreenMenu/>
      <Footer />
      </>
     )}
      
    </>
  );
};

export default withRouter(Home);
