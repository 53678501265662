import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from "react-router-dom";
import mailIcon from './material-symbols_mail.svg';
import ytIcon from './carbon_logo-youtube.svg';
import teleIcon from './mingcute_telegram-fill.svg';
import vector from './Vector 1.svg';
import vector3 from './Vector 3.svg'
import './footer.css'
import { isMobile } from "react-device-detect";

const Footer = () => {
    return (
    <div className="footer">
      {isMobile ? 
        <img class="footer-child" alt="" src={vector3}/>
      : <img class="footer-child" alt="" src={vector}/>
      }
      <div class="footer-frame bg-slate-200">
        <div className="footer-frame-parent">
          <div class="footer-frame-group">
            <div class="footer-image-3-parent">
            <Link  to='/' className='no-underline text-inherit'>
              <img class="footer-image-3-icon" alt="" src={"/images/mainLogo.png"}/>
            </Link>
            {/* <div class="footer-collectorbabu-is-your">CollectorBabu is your personal mentor to help UPSC students in their exam preparation</div> */}
          </div>
          <div className="footer-link-block-parent">
            <div class="footer-link-block">
              <div class="footer-block-heading">Follow us</div>
            </div>
            <div class="footer-contact">
            <a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><img class="mingcutetelegram-fill-icon" alt="" src={teleIcon}/></a>
            <a href="mailto:support@collectorbabu.com"><img class="mingcutetelegram-fill-icon" alt="" src={mailIcon}/></a>
            <a href="https://t.me/collector_babu_original" target="_blank" rel="noopener noreferrer"><img class="mingcutetelegram-fill-icon" alt="" src={ytIcon}/></a>
            </div>
          </div>
        </div>
        <div className="footer-link-block-group">
          <div className="footer-container ">
            <div class="footer-link-block1">
              <div class="footer-links">
              <Link to="/ai-answer-review" className='no-underline text-inherit'><div class="footer-link">AI Answer Review</div></Link>
              <Link to="/upsc-mains-2024-essay-gs-questions-model-answers" className='no-underline text-inherit'><div class="footer-link">Mains 24</div></Link>
              </div>
            </div>
            <div class="footer-link-block2">
              <div class="footer-links">
                <Link to="/pyqexplorer" className='no-underline text-inherit'><div class="footer-link">PYQ Explorer</div></Link>
                <Link to="/360-reader" className='no-underline text-inherit'><div class="footer-link">360 Reader</div></Link>
              </div>
            </div>
          </div>
          <div class="footer-container">
          <div class="footer-link-block2">
            <div class="footer-links">
              <Link to="/upsc-ias-mock-interview" className='no-underline text-inherit'><div class="footer-link">Mock Interview</div></Link>
              <Link to="/pyq-fast-track" className='no-underline text-inherit'><div class="footer-link">PYQ Fast Track</div></Link>
            </div>
          </div>
          <div class="footer-link-block2">
            <div class="footer-links">
              <Link to="/upsc-notes-zero" className='no-underline text-inherit'><div class="footer-link">Notes Zero</div></Link>
              <Link to="/flash-card" className='no-underline text-inherit'><div class="footer-link">RattafAI</div></Link>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="copyright-collector-babu-202-parent">
        <div class="footer-link">COPYRIGHT © COLLECTOR BABU 2024</div>
        <div class="footer-policies">
          <div className='footer-policy-container'>
          <div class="footer-refund">
            <ul class="footer-terms-of-service">
              <Link to="/refund" className='no-underline text-inherit'><div class="footer-refund">Refund </div></Link>
            </ul>
          </div>
          <div class="footer-refund">
            <ul class="footer-terms-of-service">
              <Link to="/cancellation" className='no-underline text-inherit'><li>Cancellation</li></Link>
            </ul>
          </div>
          </div>
          <div className='footer-policy-container'>
          <div class="footer-refund">
            <ul class="footer-terms-of-service">
              <Link to="/privacy-policy" className='no-underline text-inherit'><li>Privacy Policy</li></Link>
            </ul>
          </div>
          <div class="footer-refund">
            <ul class="footer-terms-of-service">
              <Link to="/terms-of-service" className='no-underline text-inherit'><li>Terms of Service</li></Link>
            </ul>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    );
}

export default Footer