import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import UserContext from "../../UserContext";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import withRouter from "../../withRouter";
import ReactGA from "react-ga4";
import landing from "./landing.jpg";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { isBrowser, isMobile } from "react-device-detect";
import './login.css';
import RightImage from './7140416_3487927.jpg';
import GoogleLogo from './google-logo-9808.png';

function Copyright(props) {
  return (
    <Box {...props}>
      <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 2 }}>
        Contact us at{" "}
        <Link
          href={`${isMobile ? "mailto:support@collectorbabu.com" : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@collectorbabu.com"}`}
          target="_blank"
          rel="noreferrer">
          support@collectorbabu.com
        </Link>
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://collectorbabu.com">
          Collector Babu
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
}

const theme = createTheme();

const Login = (props) => {
  // clientID: 553125461546-ti2e6ul3g3n5ik3ohm2fr7ojoqebrlhq.apps.googleusercontent.com
  // client secret: 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
  const { loggedInUser, responseFromGoogleLogin } = props;
  const [showLoginProgressBar, setShowLoginProgressBar] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();
  let [loggedInUserDetails, setLoggedInUserDetails] = React.useContext(UserContext);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });

  useEffect(() => {
    let name = Cookies.get("user_name");
    let user_id = Cookies.get("user_id");
    let email = Cookies.get("email");

    if (name && user_id && email) {
      let apiData = {
        name: name,
        user_id: user_id,
        email: email,
      };

      internalLogin(apiData, undefined, false);
    }
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      //
      setShowLoginProgressBar(true);
      const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      });
      //
      let apiData = {
        name: userInfo.data.name,
        email: userInfo.data.email,
        domain: "collectorbabu",
      };

      internalLogin(apiData, userInfo, true);
    },
    onError: (errorResponse) => {
      setShowLoginProgressBar(false);
    },
  });

  // makes request to login_via_sso and checks if cookie has been set then redirects to /
  const internalLogin = async (data, userInfo, setCookie = false) => {
    try {
      let config = {
        method: "post",
        url: "https://collectorbabu.com/api/user/login_via_sso",
        headers: {
          token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
          "Content-Type": "application/json",
        },
        data: data,
      };

      let response = await axios(config);
      setShowLoginProgressBar(false);

      if (setCookie) {
        responseFromGoogleLogin(Object.assign({}, userInfo.data, { user_id: response.data.user_id, premium: response.data.premium }));
        setLoggedInUserDetails(Object.assign({}, userInfo.data, { user_id: response.data.user_id, premium: response.data.premium }));
        Cookies.set("google_info", JSON.stringify(userInfo.data), { expires: 90 });
        Cookies.set("user_name", userInfo.data.name, { expires: 90 });
        Cookies.set("user_id", response.data.user_id, { expires: 90 });
        Cookies.set("email", userInfo.data.email, { expires: 90 });
        navigate(location.state ? location.state.from : "/ai-answer-review");
      } else {
        // match user_if from response with the data stored in cookie
        if (response.data.user_id === data.user_id) {
          // responseFromGoogleLogin(Object.assign({}, userInfo.data, { user_id: response.data.user_id }));

          responseFromGoogleLogin(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: data.user_id, premium: response.data.premium })
          );
          setLoggedInUserDetails(
            Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: data.user_id, premium: response.data.premium })
          );
          navigate(location.state ? location.state.from : "/");
        }
      }
    } catch (error) {
      setShowLoginProgressBar(false);
    }
  };

  return (


    // <ThemeProvider theme={theme}>
    //   {showLoginProgressBar ? (
    //     <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} style={{ minHeight: "100vh" }}>
    //       <CircularProgress color="info" />
    //       {/* 
    //                     add text for loader here
    //                     <Typography></Typography>

    //                     */}
    //     </Box>
    //   ) : (
    //     <Grid container component="main" sx={{ height: "100vh" }}>
    //       <Grid
    //         item
    //         xs={false}
    //         sm={4}
    //         md={7}
    //         sx={{
    //           backgroundImage: `url(${landing})`,
    //           backgroundRepeat: "no-repeat",
    //           height: 400,
    //           width: 500,
    //           backgroundSize: "cover",
    //           backgroundPosition: "center",
    //         }}
    //       />

    //       <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    //         <Box
    //           sx={{
    //             my: 8,
    //             mx: 4,
    //             display: "flex",
    //             flexDirection: "column",
    //             alignItems: "center",
    //           }}>
    //           <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
    //             <LockOutlinedIcon />
    //           </Avatar>
    //           <Typography component="h1" variant="h5">
    //             Login
    //           </Typography>

    //           <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => googleLogin()}>
    //             Log in
    //           </Button>

    //           <Copyright sx={{ mt: 40 }} />
    //         </Box>
    //       </Grid>
    //     </Grid>
    //   )}
    // </ThemeProvider>

    <>
    <div className='base '>
        <div className='box shadow-lg'>
            <div className='left'>
                 <div className='h-[80%] w-full'>
                    <img src={RightImage} alt='' className='h-full w-full object-contain'/>
                 </div>
                 <div >
                    One Stop for Everything Civil Services
                 </div>
            </div>
            <div className='right'>
                <div className='logo w-[50%] mt-14' >
                <img src="/images/mainLogo.png" alt='CollectorBabu' />
                </div>
                
                <div className='mb-20 items-center'>
                    <div className='mt-12'>
                        Login with your Gmail account
                    </div>
                    {showLoginProgressBar? (
                    <div className='flex justify-center'>
                        <CircularProgress color="primary"/>
                    </div>
                    ):(
                    <button className=' w-full p-2 font-bold bg-white rounded-3xl flex justify-center items-center mt-6 relative' onClick={() => googleLogin()}>
                        <img src={GoogleLogo} alt="Google Logo" className='object-contain h-6 w-12 absolute top-2 left-0'/>
                        Login
                    </button>
                    )}
                    
                </div>
                <div className="tncd">
                    <label className="text-xs" htmlFor="Agree">By logging in, you agree to our <a className="text-blue-500" href="https://collectorbabu.com/terms-of-service">Terms</a> and <a className="text-blue-500" href="http://collectorbabu.com/privacy-policy">Privacy policy</a></label>
                </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default Login;
