import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery,createTheme, Box } from '@mui/material';
import { isLoggedIn} from "../../utils";
import AINavbar from '../AINavbar';
import UserContext from "../../UserContext";
import Footer from "../Footer/Footer";
import LeftPane from "../LeftPane/LeftPane";
import OnScreenMenu from '../OnScreenMenu'; 

import './index.css';



const PaymentSuccessDashboard = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const navigate = useNavigate();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  return (
    <>
      {isMobile ? (
        <>
          <LeftPane Component={PaymentSuccess} />
          <Footer />
        </>
      ) : (
        <>
          <AINavbar />
          <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <div style={{ marginTop: '120px' }}>
              <PaymentSuccess />
            </div>
          </Box>
          <OnScreenMenu />
          <Footer />
        </>
      )}
    </>
  );
};

const PaymentSuccess = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [navigate]);

  const handleRedirect = () => {
    navigate('/');
  };

  return (
    <div className="container">
      <div className="icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-credit-card"
        >
          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
          <line x1="1" y1="10" x2="23" y2="10"></line>
        </svg>
        <div className="checkmark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-check-circle"
          >
            <path d="M22 11.08V12A10 10 0 1 1 12 2a10 10 0 0 1 10 9.08"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
        </div>
      </div>
      <h1>Thank You!</h1>
      <p>Payment done Successfully</p>
      <p>
        You will be redirected to the home page in <strong>{countdown}</strong> second{countdown > 1 ? 's' : ''} or click here to return to the home page.
      </p>
      <button className="home-button" onClick={handleRedirect}>
        Home
      </button>
    </div>
  );
}

export default PaymentSuccessDashboard;
